.Titlecard {
    background-color: #FEB54C;
    background-image: url('../../assets/backgroundGradient.webp');
    color: #EAF2F8;
    width: 100%;
    /* height: 100vh; */
    left: 0;
    top: 0;
    /* padding-left: 1rem; */
    margin-top: -60px;
    padding-top: 60px;
    display: grid;
    grid-template-columns: 50% 50%;
    padding-bottom: 50px;
}

.Titlecardtext {
    padding-left: 1rem;
}

.Titlecard h1 {
    font-weight: lighter;
    font-size: 72px;
    margin: 0;
    padding: 0;
}

.DescriptionBlock {
    font-family: Consolas 'Open Sans' -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 90%;
    /* height: min-content; */
    margin: 1rem;
    /* background-color: #17202A; */
    border-radius: 10px;
    font-size: 22px;
    padding: 20px;
    padding-top: 5px;
    padding-bottom: 30px;
    background: rgba(5, 5, 5, 0.7);
}

.Circles {
    margin: 0;
    margin-bottom: -20px;
}

.Circles span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.Red {
    background-color: #FF605C;
}

.Yellow {
    background-color: #FFBD44;
}

.Green {
    background-color: #00CA4E;
}

.DescriptionBlock p {
    /* color: #3498DB; */
    color: #fff;
}

.Value {
    color: #FEB54C;
    /* color: #fff; */
}

.Bracket {
    color: #fff;
}

.Semicolon {
    color: #ffffff;
}

.DescriptionBlock ul {
    list-style-type: none;
    /* color: #FEB54C; */
    /* color: #3498DB; */
    color: #fff;
}

.Comma {
    color: #ffffff;
}

.Equal {
    color: #ffffff;
}

.Titlecard a {
    text-decoration: none;
    color: #ffffff;
    font-size: 36px;
    border: 1px solid #ffffff;
    width: 323px;
    height: 52px;
    border-radius: 5px;
    padding: 5px 30px;
    margin: 1rem;
    transition: all .2s;
    font-weight: lighter;
}

.Titlecard a svg{
    width: 46px;
    height: 38px;
    align-self: baseline;
    margin-right: 10px;
    margin-bottom: -7px;
}

.Titlecard a:hover, .Titlecard a:focus {
    /* background: rgba(235, 70, 70, 1); */
    color: rgb(235, 70, 70);
    transition: all .2s;
    /* border-color: rgb(235, 70, 70) */
    background-color: black;
    border-color: black;
}

.imgWrapper {
    width: 90%;
    height: 530px;
    border-radius: 20px;
    margin: 20px;
    object-fit: cover;
    /* filter: grayscale(100%); */
}

.DesktopFace {
    border-radius: 20px;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1146px) {
    .Titlecard {
        display: block;
    }

    .imgWrapper {
        display: none;
    }

    .DesktopFace {
        display: none;
    }

    .Titlecard a {
        margin-left: 0;
    }
}

@media screen and (max-width: 630px) {
    .Titlecard h1 {
        font-size: 64px;
    }

    .DescriptionBlock {
        width: 90%;
        margin-left: -10px;
        font-size: 18px;
        height: max-content;
        padding: 20px;
    }
}