.About {
    background-color: #FEB54C;
    background-image: url('../../assets/backgroundGradient.webp');
    color: #EAF2F8;
    width: 100%;
    /* height: 100vh; */
    left: 0;
    top: 0;
    /* padding-left: 1rem; */
    padding-bottom: 50px;
}

.About h1 {
    font-weight: lighter;
    font-size: 72px;
    margin: 1rem;
    margin-top: 0;
    margin-bottom: 30px;
    color: #fff;
}

.AboutMeSection {
    display: grid;
    grid-template-columns: 40% 60%;
    width: 75%;
    margin: auto;
    align-self: center;
    margin-top: 40px;
    /* background-color: #34495E; */
    border-radius: 20px;
    padding: 10px;
    max-width: 1200px;
}

.WhoAmIBlock {
    z-index: 100;
    width: 100%;
    height: auto;
    padding-bottom: 20px;
    background-color: #5D6D7E;
    color: #EAF2F8;
    border-radius: 20px;
    /* box-shadow: -4px 3px 4px rgba(0, 0, 0, 0.25); */
    background: rgb(27, 27, 27);
    text-align: center;
}

.WhoAmIImg {
    width: 111px;
    height: 112.01px;
    border-radius: 61.5px;
    margin-top: -20px;
}

.WhoAmIHeader {
    font-size: 40px;
    margin: 0;
    font-weight: lighter;
    color: rgb(235, 70, 70);
}

.WhoAmIHr {
    width: 190.01px;
    color: #EAF2F8;
}

.WhoAmIText {
    font-size: 18px;
    margin: 0px 15px;
    margin-bottom: 25px;
}

.WhoAmILink {
    text-decoration: none;
    color: rgb(235, 70, 70);
    font-size: 24px;
    border: 1px solid rgb(235, 70, 70);
    width: 188px;
    height: 38px;
    border-radius: 5px;
    padding: 5px 10px;
    transition: all .2s;
}

.WhoAmILink:hover, .WhoAmILink:focus {
    background-color: rgb(235, 70, 70);
    color: #EAF2F8;
    transition: all .2s;
}

.SkillsBlock {
    width: 100%;
    border-radius: 0 20px 20px 0;
    background-color: #17202A;
    font-family: Consolas, 'Open Sans' -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    z-index: 50;
    margin-top: 30px;
    padding-bottom: 10px;
    /* box-shadow: -4px 3px 4px rgba(0, 0, 0, 0.25); */
    background: rgba(28, 28, 29, 0.9);
    margin-left: -15px;
}

.SkillsBlockHeader {
    font-size: 28px;
    font-family: 'Open Sans';
    font-weight: lighter;
    color: #EAF2F8;
    margin: 0;
    margin-left: 1.5rem;
    margin-top: 1rem;
    text-align: left;
}

.SkillsBlockHr {
    color: #EAF2F8;
    width: 90%;
    text-align: left;
    margin-left: 25px;
}

.Skills {
    width: 95%;
    margin: auto;
}

.Skill {
    display: inline-block;
    text-align: center;
    margin: 15px 10px;
    justify-content: center;
}

.Skills svg, .Skills img {
    margin-right: 7px;
    margin-bottom: -10px;
    height: 33.33px;
    width: 33.33px;
}

.SkillsList {
    color: #F0B27A;
    font-size: 18px;
    list-style-type: none;
    margin-left: -10px;
}

.SkillsList li {
    display: grid;
    grid-template-columns: 20% 15% 60%;
}

.SkillPercent {
    align-content: right;
}

.SkillsList svg, .SkillsList img {
    margin-bottom: -10px;
    height: 33.33px;
    width: 33.33px;
}

.WhiteBar {
    background-color: #EAF2F8;
    width: 100%;
    height: 21px;
    border-radius: 3px;
    float: right;
    margin-top: 7px;
    margin-left: 5px;
    margin-right: 10px;
    overflow: hidden;
}

.HTMLBar {
    background-color: #E44D26;
    width: 100%;
    height: 21px;
    border-radius: 3px;
}

.CSSBar {
    background-color: #3C99DC;
    width: 90%;
    height: 21px;
    border-radius: 3px;
}

.JSBar {
    background-color: #F0DB4F;
    width: 95%;
    height: 21px;
    border-radius: 3px;
}

.NodeBar {
    background-color: #68A063;
    width: 80%;
    height: 21px;
    border-radius: 3px;
}

.ReactBar {
    background-color: #61DBFB;
    width: 95%;
    height: 21px;
    border-radius: 3px;
}

.ReactNativeBar {
    background-color: #e74c3c;
    width: 90%;
    height: 21px;
    border-radius: 3px;
}

.ReduxBar {
    background-color: #764ABC;
    width: 80%;
    height: 21px;
    border-radius: 3px;
}

.WebDesignBar {
    background-color: #F1948A;
    width: 80%;
    height: 21px;
    border-radius: 3px;
}

@media screen and (max-width: 1280px) {
    .SkillsList li {
        grid-template-columns: 20% 20% 55%;
    }
}

@media screen and (max-width: 1128px) {
    .AboutBlocks {
        display: grid;
        grid-template-columns: auto auto;
        align-self: center;
        margin: auto;
    }
    
    .AboutBlock {
        margin: 0;
        margin-bottom: 15px;
        align-self: center;
    }

    .BackgroundBlock {
        display: none;
    }

    .AboutMeSection {
        display: block;
        width: 100%;
        padding: 0;
    }

    .WhoAmIBlock {
        margin: auto;
        width: 90%;
        margin-top: 30px;
    }

    .SkillsBlock {
        margin: auto;
        width: 95%;
        margin-top: 15px;
        border-radius: 20px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 627px) {
    .About {
        padding-bottom: 30;
    }

    .AboutBlocks {
        display: block;
        width: 90%;
        margin: auto;
        align-items: center;
    }

    .AboutBlock {
        margin: auto;
        margin-top: 10px;
    }

    .WhoAmIBlock {
        width: 90%;
    }

    .SkillsBlock {
        width: 99%;
        margin: auto;
    }

    .SkillsList li {
        grid-template-columns: 35% 25% 35%;
    }
}