.WorkItem {
    width: 95%;
    align-self: center;
    margin: auto;
    height: 300px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
    background-color: #FFFFFF;
}

.WorkItemBackground {
    background-color: rgba(43, 46, 49, 0.93);
    width: 100%;
    height: 300px;
    border-radius: 10px;
    opacity: 0;
    transition: all .5s;
}

.WorkItemBackground:hover {
    opacity: 1;
    transition: all .5s;
}

.WorkItemHeader {
    color: #FFFFFF;
    font-size: 30px;
    margin: 0;
    margin-left: 10px;
    font-weight: lighter;
    padding-top: 10px;
}

.WorkItemText {
    color: #FFFFFF;
    font-size: 18px;
    margin-left: 20px;
    margin-right: 5px;
}

.WorkItemTech {
    color: #FFFFFF;
    font-size: 24px;
    margin-left: 15px;
}

.WorkItemTech svg, .WorkItemTech img {
    height: 33.33px;
    margin-left: 10px;
    margin-bottom: -10px;
}

.WorkItemLink {
    color: #FFFFFF;
    margin: 0;
    margin-top: 20px;
    margin-left: 10px;
    font-size: 20px;
}

.WorkItemLink a {
    text-decoration: none;
    color: #f36320;
    transition: all .5s;
}

.WorkItemLink a:hover {
    color: #f10d0d;
    transition: all .5s;
}

/*? Image backgrounds found in index.css */

@media screen and (max-width: 1116px) {
    .WorkItem {
        width: 100%;
        padding-bottom: 40px;
    }

    .WorkItemBackground {
        opacity: 1;
        padding-bottom: 40px;
    }
}