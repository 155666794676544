.Contact {
    /* background-color: #283747; */
    background-color: rgb(20, 20, 20);
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    margin-bottom: 0;
    /* padding-left: 1rem; */
}

.ContactHeader {
    font-weight: lighter;
    font-size: 72px;
    margin: 0;
    margin-left: 1rem;
    margin-bottom: 30px;
    color: rgb(235, 70, 70);
}

.ContactMain {
    max-width: 1450px;
    display: grid;
    grid-template-columns: 40% 60%;
    margin-bottom: 0;
}

.ContactFormContainer {
    background-color: #222729;
    border-radius: 0 5px 0 0;
    margin-bottom: 0;
}

.ContactForm {
    margin: auto;
    width: 80%;
    padding-top: 20px;
}

.FormInput {
    height: 28px;
    width: 100%;
    font-weight: lighter;
    font-size: 18px;
    border-radius: 3px;
    border: none;
    margin-bottom: 10px;
    font-family: 'Open Sans';
    padding-left: 5px;
}

.FormMessage {
    width: 100%;
    font-size: 18px;
    font-weight: lighter;
    height: 100px;
    border-radius: 3px;
    border: none;
    font-family: 'Open Sans';
    padding-left: 5px;
    margin-bottom: 10px;
    resize: none;
    padding-top: 5px;
    scrollbar-width: thin;
}

.FormButton {
    width: 300px;
    height: 35px;
    font-size: 20px;
    color: #e74c3c;
    border: 1px solid #e74c3c;
    background-color: transparent;
    border-radius: 3px;
    transition: all .2s;
    font-family: 'Open Sans';
    font-weight: lighter;
}

.FormButton:hover, .FormButton:focus {
    background-color: #e74c3c;
    color: #ffffff;
    transition: all .2s;
}

.Error {
    color: #e74c3c;
    font-size: 24px;
    margin-top: 5px;
}

.Success {
    color: #48c9b0;
    font-size: 24px;
    margin-top: 5px;
}

.FollowMe {
    background-color: rgb(20, 20, 20);
    border-radius: 0 5px 0 0;
    width: 362px;
    margin-bottom: 0;
    padding: 2px;
    margin-top: 20px;
}

.FollowMeList {
    list-style: none;
    margin-bottom: 10px;
}

.FollowMeListItem {
    margin-top: 10px;
}

.FollowMeSvg {
    height: 30px;
    width: 30px;
    margin-bottom: -7px;
}

.FollowMeLink {
    color: #ffffff;
    font-size: 18px;
    font-weight: lighter;
    text-decoration: none;
    margin-left: 10px;
    transition: all .2s;
}

.FollowMeLink:hover, .FollowMeLink:focus {
    color: #f15555;
    transition: all .2s;
}

.ContactRight {
    width: 95%;
    margin-left: 20px;
    background-color: #e74c3c;
    border-radius: 5px;
    text-align: center;
    padding-bottom: 10px;
    height: min-content;
}

.ContactImg {
    width: 97%;
    height: 450px;
    object-fit: cover;
    border-radius: 5px;
    margin-top: 10px;
}

@media screen and (max-width: 1000px){
    .FollowMe {
        width: 100%;
    }
}

@media screen and (max-width: 908px){
    .ContactRight {
        display: none;
    }

    .ContactMain {
        display: block;
    }
}

@media screen and (max-width: 600px){
    .Contact {
        padding-bottom: 80px;
    }

    .FollowMe {
        width: 99%;
    }
}