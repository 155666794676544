.Uses {
    background-color: #000000;
    left: 0;
    top: 0;
    /* padding-left: 1rem; */
    color: #EAF2F8;
    margin-bottom: 0;
    font-family: 'Open Sans';
    font-weight: lighter;
    margin-top: -60px;
    padding-top: 60px;
}

.UsesHeader {
    font-weight: lighter;
    font-size: 72px;
    margin: 1rem;
    margin-bottom: 30px;
    margin-top: 0;
    color: rgb(235, 70, 70);
}

.UsesSubheader {
    margin-left: 2rem;
    font-size: 24px;
    font-weight: lighter;
}

.UsesNav {
    background-color: rgb(235, 70, 70);
    width: max-content;
    border-radius: 0 5px 5px 0;
    padding: 0 10px;
}

.UsesMain {
    background-image: url('../assets/backgroundGradient.webp');
    padding-top: 0px;
    background-color: #FEB54C;
    background-position: bottom left;
    height: 100%;
}

.UsesLink {
    background: transparent;
    border: none;
    font-family: 'Open Sans';
    color: #aaadaf;
    font-size: 20px;
    font-weight: lighter;
    padding: 10px;
    transition: all .3s;
}

.UsesLink:hover {
    background-color: #242424;
    transition: all .3s;
}

.UsesLink:focus, .UsesLinkActive:focus {
    border: none;
    outline: none;
}

.UsesLinkActive {
    background-color: #000000;
    border: none;
    font-family: 'Open Sans';
    color: #ffffff;
    font-size: 20px;
    font-weight: lighter;
    padding: 10px;
    transition: all .3s;
    height: 100%;
}

.UsesItems {
    width: 90%;
    margin: auto;
    margin-top: 20px;
    max-width: 1450px;
}

.UsesItems span {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%; 
}

@media only screen and (max-width: 1300px) {
    .UsesItems span {
        grid-template-columns: 50% 50%;
    }
}

@media only screen and (max-width: 800px) {
    .UsesItems span {
        display: inline-block;
    }
    .UsesMain {
        padding-bottom: 100px;
    }
}