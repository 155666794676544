.Layout {
    display: block;
    /* padding: 10px; */
    margin: auto;
    width: 95%;
    text-align: center;
    font-family: 'Open Sans';
}

.Layout h1 {
    font-size: 64px;
    margin: 0;
}

.Layout a {
    text-decoration: none;
    color: #000000;
    transition: all .2s;
}

.Layout a:hover {
    color: #f15555;
    transition: all .2s;
}

@media screen and (max-width: 600px) {
    .Layout {
        padding-bottom: 90px;
    }
}