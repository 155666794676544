.COVID {
    /* background-color: #283747; */
    background-color: #FEB54C;
    background-image: url('../assets/backgroundGradient.webp');
    height: 100vh;
    color: #EAF2F8;
    font-family: 'Open Sans';
    margin: 0;
    margin-top: -60px;
    padding-top: 60px;
}

@media screen and (max-width: 1126px) {
    .COVID {
        height: 100%;
    }
}