:root {
  font-size: 16px;
  font-family: 'Open Sans' -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: black;
  --bg-secondary: #141418;
  --transition-speed: 600ms;
  scrollbar-width: thin;
  scrollbar-color: #f15555 rgb(20, 20, 20);
  overflow-x: hidden;
  background-color: #000000;
}

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: rgb(20, 20, 20);
}

::-webkit-scrollbar-thumb {
  background: #f17955;
  -webkit-border-radius: 1ex;
  border-radius: 1ex;
  /* -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75); */
}

html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  scroll-behavior: smooth;
  background-color: #000000;
}

/* body::selection {
  background: #df49a6;
  color: #F7F9F9;
}

body::-moz-selection {
  background: #df49a6;
  color: #F7F9F9; */
/* } */

body {
  font-family: 'Open Sans';
  color: black;
  background-color: #000000;
  margin: 0;
  padding: 0;
}

main {
  font-family: 'Open Sans';
  margin-left: 5rem;
}

.navbar {
  font-family: 'Open Sans';
  position: fixed;
  background-color: var(--bg-primary);
  transition: width 600ms ease;
  z-index: 1000000000000;
}

.navbar-nav {
  font-family: 'Open Sans';
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.nav-item, .mobile_nav-item {
  font-family: 'Open Sans';
  width: 100%;
}

.mobile-only_nav-item {
  display: none;
}

.bottom-items {
  margin-top: auto;
  width: 100%;
}

.nav-link {
  font-family: 'Open Sans';
  display: flex;
  align-items: center;
  height: 5rem;
  color: var(--text-primary);
  text-decoration: none;
  filter: grayscale(100%) opacity(0.7);
  transition: var(--transition-speed);
  font-size: 24px;
  font-weight: lighter;
}

.nav-link:hover {
  filter: grayscale(0%) opacity(1);
  background: var(--bg-secondary);
  color: var(--text-secondary);
}

.link-text {
  font-family: 'Open Sans';
  display: none;
  margin-left: 1rem;
  transition: all var(--transition-speed);
}

.nav-link svg {
  width: 2rem;
  min-width: 2rem;
  margin: 0 1.5rem;
}

.fa-primary {
  color: #5499C7;
}

.fa-secondary {
  color: #df49a6;
}

.fa-primary,
.fa-secondary {
  transition: var(--transition-speed);
}

.logo {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--text-secondary);
  background: var(--bg-primary);
  font-size: 1.5rem;
  letter-spacing: 0.3ch;
  width: 100%;
}

.logo .nav-link {
  filter: grayscale(0%)
}

.logo img {
  transform: rotate(0deg);
  transition: var(--transition-speed);
  width: 50px;
  /* height: ; */
  margin-left: 15px;
}

.logo-text {
  display: inline;
  position: absolute;
  left: -999px;
  transition: var(--transition-speed);
}

.navbar:hover .logo svg {
  transform: rotate(-180deg);
}

/* Small screens */
@media only screen and (max-width: 600px) {
  .navbar {
    bottom: 0;
    width: 100vw;
    height: 5rem;
    padding-bottom: 20px;
  }

  .logo {
    display: none;
  }

  .navbar-nav {
    flex-direction: row;
  }

  .nav-item {
    display: none;
  }

  .mobile-only_nav-item {
    display: block;
    font-family: 'Open Sans';
    width: 100%;
  }

  .bottom-items {
    display: none;
  }

  .nav-link {
    justify-content: center;
  }

  main {
    margin: 0;
  }
}

/* @media only screen and (max-width: 500px) {
  .bottom-items .nav-item {
    display: none;
  }
} */

/* @media only screen and (max-width: 378px) {
  .bottom-items {
    display: none;
  }
} */

@media screen and (max-height: 412px) {
  .navbar {
    display: none;
  }

  main {
    margin: 0;
  }
}

/* Large screens */
@media only screen and (min-width: 600px) {
  .navbar {
    top: 0;
    width: 5rem;
    height: 100vh;
  }

  .navbar:hover {
    width: 16rem;
  }

  .navbar:hover .link-text {
    display: inline;
  }

  .navbar:hover .logo svg
  {
    margin-left: 11rem;
  }

  .navbar:hover .logo-text
  {
    left: 0px;
  }
}

/* fade stuff */

.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform .9s ease-out;
  will-change: opacity, visibility;
}

.fade-in-right {
  opacity: 0;
  transform: translateX(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform .9s ease-out;
  will-change: opacity, visibility;
}

.fade-in-left {
  opacity: 0;
  transform: translateX(-10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform .9s ease-out;
  will-change: opacity, visibility;
}

.fade-in-top {
  opacity: 0;
  transform: translateY(-10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform .9s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible, .fade-in-left.is-visible, .fade-in-right.is-visible, .fade-in-top.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}



/* Images In Work Section */
#Dragonfly {
  background-image: url('./assets/dragonfly.webp');
}

#IdeaMakers {
  background-image: url('./assets/IdeaMakers.webp');
}

#CentralValleyFoods {
  background-image: url('./assets/CentralValleyFoods1.webp');
}

#SchwabBakery {
  background-image: url('./assets/schwabBakery.webp');
}

#WiserStorage {
  background-image: url('./assets/WiserStorage.webp');
}

#VeteranLawncare {
  background-image: url('./assets/VeteranLawncare.webp');
}

#GalaxyWatch {
  background-image: url('./assets/watch.png');
}

#CovidTracker {
  background-image: url('./assets/covidTracker.webp');
}