.Work {
    /* background-color: #486d92; */
    background-color: rgb(20, 20, 20);
    width: 100%;
    left: 0;
    top: 0;
    /* padding-left: 1rem; */
    padding-bottom: 30px;
}

.WorkHeader {
    font-weight: lighter;
    font-size: 72px;
    margin: 1rem;
    margin-top: 0;
    margin-bottom: 30px;
    /* color: #EAF2F8; */
    color: rgb(235, 70, 70);
}

.workHistory {
    align-self: center;
    width: 80%;
    max-width: 1450px;
    background-image: url('../../assets/backgroundGradient.webp');
    background-size: cover;
    border-radius: 10px;
    margin: auto;
    padding: 0px;
    margin-top: 20px;
    margin-bottom: 40px;
    overflow: hidden;
}

.workHistoryHeader {
    margin: 0;
    font-weight: lighter;
    font-size: 50px;
    /* background: rgba(5, 5, 5, 0.7); */
    background-position: 30% 60%;
    border-radius: 10px 10px 0 0;
    padding-left: 10px;
    padding-bottom: 5px;
    /* color: #FEB54C; */
    color: #ffffff;
}

.workHistoryItems {
    margin: 10px;
}

.job {
    background: rgba(5, 5, 5, 0.7);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    color: #fff;
}

.jobTitle {
    font-size: 22px;
    margin: 0;
    font-weight: bold;
}

.jobEmployer {
    margin: 0;
    font-size: 16px;
    color: #ffffffaa
}

.WorkItems {
    width: 95%;
    margin: auto;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    max-width: 1450px;
    align-items: center;
}

@media screen and (max-width: 1364px){
    .WorkItems {
        grid-template-columns: 50% 50%;
    }
}

@media screen and (max-width: 1116px){
    .WorkItems {
        grid-template-columns: auto;
    }
}

@media screen and (max-width: 360px) {
    .WorkItems {
        margin: 3px;
    }
}