.Stats {
    box-sizing: border-box;
    padding: 20px;
    font-size: 1.5rem;
    text-align: center;
    width: 45%;
    display: inline-block;
    font-weight: lighter;
    padding-bottom: 20px;
    border-radius: 5px;
    /* background-color: #283747; */
    background: rgba(55, 55, 58, 0.6);
    margin-left: 10px;
}

.Stats h3 {
    font-weight: normal;
}

.Stats h2 {
    margin: 0;
}

@media screen and (max-width: 1126px) {
    .Stats {
        display: block;
        margin: auto;
        margin-top: 20px;
        width: 90%; 
    }
}